import React from 'react'

const LoadUnloadDurationsByCommodityGroups = () => {
    return (
        <div>
            LoadUnloadDurationsByCommodityGroups
        </div>
    )
}

export default LoadUnloadDurationsByCommodityGroups
