import React, { useState, useEffect } from "react";
import User from "../../assets/images/users/user-2.jpg";
import Profileinfo from "./profileinfo";

function PlanningprofileByIdheader(props) {
const {profileById}=props
  const [tabSelected, settabSelected] = useState({
    planning: true,
    Users:false
  
  });
  const tabClickHandler = (e, tabname) => {
    if (tabname === "planning") {
      settabSelected({
        planning: true,
        Users:false
      });
    }
    if (tabname === "Users") {
      settabSelected({
        planning: false,
        Users:true
       
      });
    }
    
   
    props.parentcallback(tabname);
  };
  
  
  return (
    <div className="row df mt_30">
      <div className="tabs">
        <input
          type="radio"
          name="tabs"
          id="tabone"
          checked={tabSelected.planning}
          readOnly
          onClick={(e) => tabClickHandler(e, "planning")}
        />
        <label for="tabone">Planning</label>
        <Profileinfo profileById={profileById}/>
        <input
          type="radio"
          name="tabs"
          id="tabtwo"
          defaultChecked={tabSelected.Users}
          onClick={(e) => tabClickHandler(e, "Users")}
        />
        <label htmlFor="tabtwo">Users</label>
        <Profileinfo profileById={profileById}/>

      </div>
    </div>
  );
}

export default PlanningprofileByIdheader;
