import { useState, useEffect } from "react";


const Profileinfo = (props) => {
 const{profileById}=props
  return (
    <>
      <div className="tab">
        <div className="row blue_header_twosidespace">
          <div className="col-md-4">
            <p className="profile_left_text_trailers1">
              Planning Profile: {profileById.name}
            </p>
          </div>
         
         
        </div>

       
      </div>
      
    </>
  );
};

export default Profileinfo;
